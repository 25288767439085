<template>
  <v-app :class="{'is-authenticated': session.state === 'authenticated' }">
    <request_interceptor></request_interceptor>
    <preloader style="position: fixed; width: 100vw; height: 100vh; z-index: 1000; left:0; top:0;" v-if="currentInterface === 'preloader'"></preloader>
    <v-component :is="currentInterface" v-if="currentInterface !== 'preloader'"></v-component>
  </v-app>
</template>

<script>


import { sessionStore } from '@/store/pinia/sessionStore'


import userInterface from './components/_app/interface.vue'
import login from './components/_app/login.vue'
import preloader from './components/_app/preloader.vue'
import request_interceptor from "./components/_app/request_interceptor";

export default {

  name: 'App',
  components: {userInterface, login, preloader, request_interceptor},

  data() {
    return {


      show: true,
    }
  },

  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },


  computed: {


    currentInterface: function() {

      if (this.session.state === 'initializing' || this.session.state === 'deauthenticating') {
        return 'preloader';
      }
      if (this.session.state === 'authenticated') {
        return 'userInterface';
      }
      return 'login'

    },

    currentLocale: function() {

      return this.session.locale;

    },



  },


  watch: {

    currentLocale: function(newVal)  {


      this.$i18n.locale = newVal;

    }

  },

  beforeMount: function () {

    let self = this;
    self.session.init();

  },

  mounted: function () {

    let self = this;

   setTimeout(function(){

      if(self.session.dark_mode === true){

        self.$vuetify.theme.dark = true;

      }

    },500);


    this.$i18n.locale = this.session.locale;


  },


}
</script>


<style lang="scss">

@import './scss/master.scss';

.v-application {

  &.theme--light {
    .v-application--wrap {
      background-color: #fbfbfb;
    }
  }


  .v-application--wrap {

    background: linear-gradient(30deg, #1e46aa, #00acc1);
    background-image: url('@/assets/rect-light.svg');
    background-size: cover;

  }

  &.is-authenticated {
    .v-application--wrap {
      background-image: none;
    }
  }

  .slide-enter-active {
    animation: bounce-in 0.5s;
  }

  .slide-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .fade-enter-active {

    animation: fade-in 0.5s reverse;

  }

  .fade-enter-leave {

    animation: fade-in 0.5s reverse;

  }

  @keyframes bounce-in {
    0% {
      transform: scale(5);
      opacity: 0;
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }


  @keyframes fade-in {
    0% {

      opacity: 0;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }


}

</style>



