/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {path: "*", name: '404', view: '404'},
    {
        path: '/',
        name: 'Dashboard',
        view: 'Dashboard'
    },
    {
        path: '/suppliers',
        name: 'supplier-search',
        view: 'Supplier/SupplierSearch'
    },
    {
        path: '/supplier/:id(\\d+)/:view?',
        name: 'supplier-editor',
        view: 'Supplier/SupplierEditorView'
    },
    {
        path: '/admin/users',
        name: 'user-search',
        view: 'User/UserSearch'
    },
    {
        path: '/admin/webhooks',
        name: 'webhook-search',
        view: 'Webhook/WebhookSearchView'
    },
    {
        path: '/admin/users/:id(\\d+)/:section?/:widget?',
        name: 'user-editor',
        view: 'User/User'
    },
    {
        path: '/profile/:section?/:widget?',
        name: 'Profile',
        view: 'User/Profile'
    },
    {
        path: '/settings',
        name: 'Settings',
        view: 'Settings'
    },
    {
        path: '/orders/orders',
        name: 'order-search',
        view: 'Orders/OrderSearchView'
    },
    {
        path: '/orders/shipping',
        name: 'shipping',
        view: 'Orders/SearchOrdersForShipping'
    },
    {
        path: '/orders/shipping/:id(\\d+)',
        name: 'shipping-editor',
        view: 'Orders/Editor2'
    },
    {
        path: '/orders/parcels',
        name: 'ParcelSearch',
        view: 'Orders/ParcelSearch'
    },
    {
        path: '/orders/:id(\\d+)',
        name: 'Order Editor',
        view: 'Orders/Editor'
    },
    {
        path: '/orders/:id(\\d+)/:view?',
        name: 'order-manager',
        view: 'Orders/Editor'
    },

    {
        path: '/shipment/:id(\\d+)/:view?',
        name: 'shipment-manager',
        view: 'Orders/ShipmentEditor'
    },
    {
        path: '/invoices',
        name: 'Invoices',
        view: 'Invoices/InvoiceSearchView'
    },
    {
        path: '/invoices/:id(\\d+)',
        name: 'invoice-manager',
        view: 'Invoices/Editor'
    },
    {
        path: '/payments',
        name: 'Payments',
        view: 'Payments/PaymentSearchView'
    },
    {
        path: '/refunds',
        name: 'refund-search',
        view: 'Payments/RefundSearchView'
    },
    {
        path: '/products/edit/:id(\\d+)/:section?/:widget?',
        name: 'product-editor',
        view: 'Products/Editor'
    },
    {
        path: '/products/:view?/:tax?/:term?',
        name: 'product-search',
        view: 'Products/ProductSearchView'
    },
    {
        path: '/product-tags',
        name: 'Product Tags',
        view: 'Products/Tags'
    },
    {
        path: '/product-categories',
        name: 'Product Categories',
        view: 'Products/Categories'
    },

    {
        path: '/product-attributes',
        name: 'attribute-editor',
        view: 'Products/Attributes'
    },

    {
        path: '/product-attributes/pag_:group',
        name: 'attribute-editor-group',
        view: 'Products/Attributes'
    },

    {
        path: '/product-attributes/pa_:attribute',
        name: 'attribute-editor-attribute-without-group',
        view: 'Products/Attributes'
    },

    {
        path: '/product-attributes/pag_:group/pa_:attribute',
        name: 'attribute-editor-attribute',
        view: 'Products/Attributes'
    },

    {
        path: '/product-media',
        name: 'Product Media',
        view: 'Media/MediaSearch'
    },
    {
        path: '/customers',
        name: 'customer-search',
        view: 'Customers/CustomerSearch'
    },
    {
        path: '/customers/:id(\\d+)/:section?/:widget?',
        name: 'customer-manager',
        view: 'Customers/Editor'
    },
    {
        path: '/shops',
        name: 'shop-search',
        view: 'Shop/Shops'
    },
    {
        path: '/shipping-settings/:section?/:widget?',
        name: 'ShippingMethods',
        view: 'Shop/ShippingMethodView'
    },
    {
        path: '/payment-settings/:section?/:widget?',
        name: 'PaymentMethods',
        view: 'Shop/PaymentMethodView'
    },

    {
        path: '/shops/:id(\\d+)/:section?/:widget?',
        name: 'shop-manager',
        view: 'Shop/ShopEditor'
    },
    {
        path: '/translations',
        name: 'translation-search',
        view: 'Translator/TranslationSearch'
    },
    {
        path: '/translations/:id(\\d+)',
        name: 'translation-editor',
        view: 'Translator/TranslationEditor'
    },
    {
        path: '/admin/api-log',
        name: 'APILog',
        view: 'Admin/ApiLog'
    },
    {
        path: '/admin/cron',
        name: 'CronJobs',
        view: 'Admin/CronJobs'
    },
    {
        path: '/admin/export',
        name: 'ExportJobs',
        view: 'Admin/ExportJobs'
    },
    {
        path: '/admin/sync-queue',
        name: 'SyncQueue',
        view: 'Admin/SyncQueue'
    },
    {
        path: '/admin/communication',
        name: 'Communication',
        view: 'Admin/Communication'
    },
    {
        path: '/admin/api-log/:id(\\d+)',
        name: 'ApiLogEntry',
        view: 'Admin/ApiLogEntry'
    },

    {
        path: '/test',
        name: 'Test',
        view: 'TestView'
    }
]
